import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage;
  private SECRET_KEY = environment.LOCAL_STORAGE_SECRET;

  constructor() {
    this.storage = localStorage;
  }

  hash(key: any) {
    key = CryptoJS.HmacSHA256(key, this.SECRET_KEY);
    return key.toString();
  }

  private encritpt(value: string) {
    return CryptoJS.AES.encrypt(value, this.SECRET_KEY).toString();
  }

  private decript(ciphertext: string) {
    try {
      var bytes = CryptoJS.AES.decrypt(ciphertext, this.SECRET_KEY);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
    } catch (err) {
      this.clear();
      return;
    }
  }

  public save(key: string, value: any) {
    if (typeof value != 'string') {
      value = JSON.stringify(value);
    }
    this.storage.setItem(this.hash(key), this.encritpt(value));
  }

  public read(key: string): any {
    let item = this.storage.getItem(this.hash(key));
    if (!item) {
      return this.storage.getItem(key);
    }
    return this.decript(item);
  }

  public remove(key: string) {
    return this.storage.removeItem(this.hash(key));
  }

  public removeAll() {
    this.storage.clear();
  }

  public clear() {
    this.storage.clear();
  }
}
