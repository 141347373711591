import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/_services/storage.service';

@Injectable({
  providedIn: 'any'
})
export class LogoutService {

  constructor(private storageService: StorageService,
    private router: Router) { }

  sair() {
    this.storageService.clear();
    this.router.navigate(['/login']);
  }
}
