import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './_services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'master_front';

  constructor(private storageService: StorageService, private router: Router) {}
  
  ngOnInit(): void {
    let token = this.storageService.read('token'); 
    if (!token) {
    //   this.router.navigate(['m'])
    // } else {
      this.router.navigate(['login'])
    }
  }


}
