import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../_services/storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
   exclusions: string[] = [
      '/login',
      'viacep.com.br'
   ]

   constructor(private storage: StorageService) {}

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const { method, url } = req;
      console.log(`INTERCEPTOR ${method} ${url}`);
      if (!this.exclusions.some(item => url.includes(item))) {
         const AUTH_TOKEN = this.storage.read('token');
         const authReq = req.clone({ setHeaders: { 'Authorization': 'Bearer ' + AUTH_TOKEN } });
         return next.handle(authReq);
      } else {
         return next.handle(req);
      }
   }
}