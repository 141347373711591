import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutService } from '../login/_services/logout.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    exclusions: string[] = [
        '/login',
        'viacep.com.br'
    ]
    constructor(private logoutService: LogoutService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const { method, url } = request;
            if ([401, 403].includes(err.status) && !this.exclusions.some(item => url.includes(item))) {
                // auto logout if 401 or 403 response returned from api
                this.logoutService.sair();
            }

            return throwError(() => err);
        }))
    }
}