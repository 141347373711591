import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'm',
    loadChildren: () => import('./navigation/navigation.module').then(m => m.NavigationModule)
  },
  { path: '',   redirectTo: '/m/base-de-conhecimento', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', redirectTo: '/m/base-de-conhecimento', pathMatch: 'full' },  // Wildcard route for a 404 page

]; // sets up routes constant where you define your routes

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
